<template>
  <div>
    <v-card>
      <v-app-bar dark color="primary">
        <v-icon large class="mr-3">calendar_view_week</v-icon>
        <v-toolbar-title>iPurchase</v-toolbar-title>
      </v-app-bar>
      <v-card-text>
        <v-row no-gutters>
          <v-col>
            <v-menu 
              ref="startDateMenu" 
              v-model="startDateMenu" 
              :close-on-content-click="false"
              :return-value.sync="startDate" 
              transition="scale-transition" 
              offset-y min-width="290px">

              <template v-slot:activator="{ on }">
                <v-text-field 
                  v-model="dataToSend.startDate" 
                  label="From" 
                  prepend-icon="event" 
                  readonly v-on="on">
                </v-text-field>
              </template>

              <v-date-picker 
                color="primary" 
                no-title scrollable 
                v-model="dataToSend.startDate">

                <v-spacer></v-spacer>

                <v-btn 
                  text color="primary" 
                  @click="$refs.startDateMenu.save(Date(startDate))">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col>
            <v-menu 
              ref="endDateMenu" 
              v-model="endDateMenu" 
              :close-on-content-click="false" 
              :return-value.sync="endDate"
              transition="scale-transition" 
              offset-y min-width="290px">

              <template v-slot:activator="{ on }">
                <v-text-field 
                  label="To" 
                  v-model="dataToSend.endDate" 
                  prepend-icon="event" 
                  readonly v-on="on">
                </v-text-field>
              </template>

              <v-date-picker 
                no-title color="primary" 
                scrollable 
                v-model="dataToSend.endDate">
                <v-spacer></v-spacer>

                <v-btn 
                  text color="primary" 
                  @click="$refs.endDateMenu.save(Date(endDate))">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row class="justify-center align-center" no-gutters>
          <v-btn 
            class="ma-2" 
            @click="loadData()" 
            color="primary">
            Load
          </v-btn>

          <v-btn 
            class="ma-2" 
            @click="clearFilter()" 
            color="primary">
            Clear Filter
          </v-btn>
        </v-row>
        
        <v-col cols="12" sm="6" md="4">
          <v-text-field 
            v-model="search" 
            append-icon="search" 
            label="Search" 
            filled 
            rounded 
            dense
            hide-details
          >
          </v-text-field>
        </v-col>
        <v-data-table :headers="headers" :items="datas" item-key="_id" :search="search">
        <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="$router.push({ path: `/mobile/ipurchase/${item._id}/details` })">edit</v-icon>
      </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import RestResource from "@/services/dataServiceMobile.js";
const service = new RestResource();

export default {
  data() {
    return {
      headers: [
        { text: "ID", value: "_id", sortable: true},
        { text: "User ID", value: "userId", sortable: true},
        { text: "Created at", value: "created_at", sortable: true},
        { text: "Status", value: "status", sortable: true},
        { text: "View Additional Details", value: "actions", sortable: false, align: 'center' }
      ],
      search: "",
      datas: [],
      startDate: null,
      endDate: null,
      startDateMenu: false,
      endDateMenu: false,
      loading: false,
      snack: false,
      dataToSend: {
        startDate: undefined,
        endDate: undefined,
      },
    };
  },

  mounted() {
    this.loadData();
  },


  methods: {
    async getData(params) {
      this.$setLoader();
      let res = await service.getData(params);
      this.datas = res.data.data;
      this.$disableLoader();
    },

    clearFilter() {
      this.dataToSend.startDate = undefined;
      this.dataToSend.endDate = undefined;
    },

    async loadData() {
      this.getData(this.dataToSend);
    },
  },
};
</script>